import { useEffect, useState } from 'react';
import { useWeb3 } from './useWeb3';
import ABI from '../base/ABI.json';
import { batchUpdate } from '../utils';
import * as env from '../env';
export const usePausedStatus = () => {
    const web3 = useWeb3(env.L1_RPC_URL);
    const [pausedStatus, setPausedStatus] = useState(null);
    const [isPausedLoading, setIsPausedLoading] = useState(true);
    const loadPausedStatus = async (enableLoading = true) => {
        if (enableLoading === true) {
            setIsPausedLoading(true);
        }
        // Instantiate the contract
        const contractAddress = env?.OptimismPortal;
        try {
            const contract = new web3.eth.Contract(ABI, contractAddress);
            // Get paused variable
            const status = await contract.methods.paused().call();
            // const status = false;
            if (status !== null) {
                batchUpdate(() => {
                    setPausedStatus(status);
                    if (enableLoading === true) {
                        setIsPausedLoading(false);
                    }
                });
                return status;
            }
            else {
                if (enableLoading === true) {
                    setIsPausedLoading(false);
                }
                return false;
            }
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            if (enableLoading === true) {
                setIsPausedLoading(false);
            }
            return false;
        }
    };
    useEffect(() => {
        loadPausedStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { pausedStatus, loadPausedStatus, isPausedLoading };
};
