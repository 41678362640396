import { useGitHubTokenList } from './api/useGitHubTokenList';
import { NET_ENV } from '../env';
import ABI from '../base/tokenABI/tokenABI.json';
export const useGetTokenList = ({ tokenList, nftType, isNotUseGitHubToken = false, tokenURL = '', }) => {
    // Get token list from github token list link if tokenList is empty
    let { data, isLoading } = useGitHubTokenList(tokenURL, nftType, tokenList?.length === 0);
    let assetList = [
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: '18',
            logoURI: '',
            l1Address: '',
            l2Address: '',
            ABI: ABI,
            extensions: {
                officialBridgeStatus: '',
            },
        },
    ];
    // combo bridge does not use GitHub token list
    if (NET_ENV === 'Testnet' || isNotUseGitHubToken) {
        if (tokenList && tokenList?.length > 0) {
            assetList = assetList.concat(tokenList);
        }
    }
    else {
        // Mainnet
        const githubTokens = data?.tokens || [];
        // add ABI to token object
        const mainnetTokens = githubTokens.map((token) => {
            const newTokenStructure = { ...token, ABI: ABI };
            return newTokenStructure;
        });
        // @ts-ignore
        assetList.push(...mainnetTokens);
    }
    // console.log(assetList);
    return {
        tokensList: assetList,
        isLoading: isNotUseGitHubToken ? false : isLoading,
    };
};
