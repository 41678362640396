// https://developers.google.com/tag-platform/gtagjs/configure
export function reportEvent({ command = 'event', name, data }) {
    if (typeof window === 'undefined' || !window.gtag)
        return;
    window.gtag(command, name, data);
}
const GA_PREFIX = process.env.NEXT_PUBLIC_GA_PREFIX || 'op_brg';
export const GA_MAP = {
    home: `${GA_PREFIX}.main.all.pv`,
    terms: `${GA_PREFIX}.main.terms.button.click`,
    headerLogo: `${GA_PREFIX}.main.header.logo.click`,
    headerDocs: 'cb_brg.main.header.docs.click',
    headerConnect: `${GA_PREFIX}.main.header.connect.click`,
    headerDisconnectClick: `${GA_PREFIX}.main.header.profile_disc.click`,
    headerDepositHistory: `${GA_PREFIX}.main.header.profile_d_his.click`,
    headerWithdrawHistory: `${GA_PREFIX}.main.header.profile_w_his.click`,
    profileCopy: `${GA_PREFIX}.main.header.profile_copy.click`,
    depositConnectButton: `${GA_PREFIX}.deposit.connect.button.click`,
    withdrawConnectButton: `${GA_PREFIX}.withdraw.connect.button.click`,
    depositWalletClick: `${GA_PREFIX}.deposit.connect.pp_mm.click`,
    withdrawWalletClick: `${GA_PREFIX}.withdraw.connect.pp_mm.click`,
    depositWalletClose: `${GA_PREFIX}.deposit.connect.pp_close.click`,
    withdrawWalletClose: `${GA_PREFIX}.withdraw.connect.pp_close.click`,
    faucet: `${GA_PREFIX}.deposit.faucet.button.click`,
    deposit: `${GA_PREFIX}.deposit.deposit.button.click`,
    depositTokenClick: `${GA_PREFIX}.deposit.token.click.click`,
    tryAgain: `${GA_PREFIX}.deposit.send_again.click`,
    withdraw: `${GA_PREFIX}.withdraw.withdraw.button.click`,
    withdrawTokenClick: `${GA_PREFIX}.withdraw.token.click.click`,
    prove: `${GA_PREFIX}.history.withdraw.prove.click`,
    finalize: `${GA_PREFIX}.history.withdraw.final.click`,
    approve: `${GA_PREFIX}.deposit.approve_set.button.click`,
    resetApprove: `${GA_PREFIX}.deposit.approve_reset.button.click`,
    viewl1Click: `${GA_PREFIX}.deposit.submitted.explorer.click`,
    thirdPartyClick: `${GA_PREFIX}.3rd_party.poly.card.click`,
    withdrawThirdPartyClick: `${GA_PREFIX}.withdraw.tab.3rd.click`,
    withdrawOfficialClick: `${GA_PREFIX}.withdraw.tab.official.click`,
    depositHistory: `${GA_PREFIX}.deposit.submitted.history.click`,
    depositTokenSelectionButton: `${GA_PREFIX}.deposit.token.select.click`,
    withdrawTokenSelectionButton: `${GA_PREFIX}.withdraw.token.select.click`,
    withdrawPopupClose: `${GA_PREFIX}.wtd.popup.close.click`,
    withdrawInitClick: `${GA_PREFIX}.wtd.popup.initiate.click`,
    initTxLinkClick: `${GA_PREFIX}.wtd.popup.transaction1.click`,
    withdrawProveClick: `${GA_PREFIX}.wtd.popup.prove.click`,
    proveTxLinkClick: `${GA_PREFIX}.wtd.popup.transaction2.click`,
    withdrawFinalizeClick: `${GA_PREFIX}.wtd.popup.finalize.click`,
    finalizeTxLinkClick: `${GA_PREFIX}.wtd.popup.transaction3.click`,
    withdrawPopupSwitch: `${GA_PREFIX}.wtd.popup.switch_network.click`,
    withdrawPopupCheckBox: `${GA_PREFIX}.wtd.popup.checkbox.click`,
    withdrawPopupHistoryLink: `${GA_PREFIX}.wtd.popup.history.click`,
    depositSwitch: `${GA_PREFIX}.deposit.switch.button.click`,
    withdrawSwitch: `${GA_PREFIX}.withdraw.switch.button.click`,
    depositAgain: `${GA_PREFIX}.deposit.submitted.again.click`,
    withdrawAgain: `${GA_PREFIX}.withdraw.submitted.again.click`,
    depositPPClose: `${GA_PREFIX}.deposit.submitted.close.click`,
    step1ViwExplorer: `${GA_PREFIX}.withdraw.step1.explorer.click`,
    step2ViwExplorer: `${GA_PREFIX}.withdraw.step2.explorer.click`,
    step3ViwExplorer: `${GA_PREFIX}.withdraw.step3.explorer.click`,
    step1ViewHistory: `${GA_PREFIX}.withdraw.step1.prove.click`,
    step2ViewHistory: `${GA_PREFIX}.withdraw.step2.final.click`,
    step3ViewHistory: `${GA_PREFIX}.withdraw.step3.history.click`,
    step1Close: `${GA_PREFIX}.withdraw.step1.close.click`,
    step2Close: `${GA_PREFIX}.withdraw.step2.close.click`,
    step3Close: `${GA_PREFIX}.withdraw.step3.close.click`,
    historyDepositTab: `${GA_PREFIX}.history.deposit.tab.click`,
    historyWithdrawTab: `${GA_PREFIX}.history.withdraw.tab.click`,
    withdrawDetailsButton: `${GA_PREFIX}.history.withdraw.details.click`,
    switchEnvDropdown: `${GA_PREFIX}.header.network.dropdown.click`,
    switchTestNet: `${GA_PREFIX}.header.network_dd.testnet.click`,
    switchMainNet: `${GA_PREFIX}.header.network_dd.mainnet.click`,
    footerDiscord: `${GA_PREFIX}.main.footer.discord.click`,
    footerTwitter: `${GA_PREFIX}.main.footer.twitter.click`,
    footerYoutube: `${GA_PREFIX}.main.footer.youtube.click`,
    footerTelegram: `${GA_PREFIX}.main.footer.telegram.click`,
    footerGithub: `${GA_PREFIX}.main.footer.github.click`,
    faqButtonClick: 'op_brg.main.help.button.click',
    faqQuestion: 'op_brg.main.help.question.click',
    faqDiscord: 'op_brg.main.help.discord.click',
    faqCloseButton: 'op_brg.main.help.close.click',
    faqRhino: 'op_brg.3rd_party.rhino.card.click ',
};
