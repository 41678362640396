export const L1_RPC_URL = process.env.NEXT_PUBLIC_L1_RPC_URL || '';
export const L2_RPC_URL = process.env.NEXT_PUBLIC_L2_RPC_URL || '';
export const L1_CHAIN_ID = String(process.env.NEXT_PUBLIC_L1_CHAIN_ID || '');
export const L2_CHAIN_ID = String(process.env.NEXT_PUBLIC_L2_CHAIN_ID || '');
export const BACKEND_DOMAIN = process.env.NEXT_PUBLIC_BACKEND_DOMAIN || '';
export const TOKEN_SYMBOL = process.env.NEXT_PUBLIC_TOKEN_SYMBOL || 'BNB';
// NET ENV
export const NetEnvList = ['Mainnet', 'Testnet'];
// export const NET_ENV: typeof NetEnvList[number] = NEXT_PUBLIC_NET_ENV || 'Mainnet';
export const NET_ENV = process.env.NEXT_PUBLIC_NET_ENV || 'Mainnet';
export const OptimismPortal = process.env.NEXT_PUBLIC_OPTIMISM_PORTAL || '';
export const OracleGetPriceAddress = '0x420000000000000000000000000000000000000F';
