import { useCallback, useRef } from 'react';
export const useDebounce = (func, wait = 800) => {
    let timer = useRef();
    return useCallback((...args) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            func(...args);
        }, wait);
    }, [func, wait]);
};
