import { useCallback, useState } from 'react';
import { useModal } from './useModal';
export const useWithdrawConfirm = () => {
    const [showWithdrawalConfirm, setShowWithdrawalConfirm] = useState(false);
    const modalData = useModal();
    const handleWithdrawToggle = useCallback((show) => {
        if (!!show) {
            setShowWithdrawalConfirm(show);
            // if (!isMobile) {
            //   // document.body.style.overflow = 'hidden';
            // }
        }
        else {
            setShowWithdrawalConfirm((preState) => !preState);
            document.body.style.overflow = '';
        }
    }, []);
    const handleWithdrawConfirmShow = useCallback((show) => {
        if (show) {
            handleWithdrawToggle(true);
            modalData.modalDispatch({ type: 'OPEN' });
        }
        else {
            handleWithdrawToggle(false);
            modalData.modalDispatch({ type: 'CLOSE' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { showWithdrawalConfirm, handleWithdrawToggle, handleWithdrawConfirmShow };
};
