import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuCloseIcon } from '@totejs/icons';
import { Box, Collapse, Flex, useDisclosure } from '@totejs/uikit';
import { useResponsive } from '../../hooks';
export const CollapseQuestion = ({ children, buttonText, questionGA, }) => {
    const { isOpen, onToggle } = useDisclosure();
    const { isMobile } = useResponsive();
    return (_jsxs(Box, { background: isOpen ? `rgba(185, 185, 187, 0.15)` : '', borderRadius: 4, padding: '12px 4px', marginLeft: 12, marginRight: 12, marginBottom: '4px', lineHeight: '18px', _hover: {
            background: `rgba(185, 185, 187, 0.15)`,
        }, children: [_jsxs(Flex, { cursor: 'pointer', fontSize: 14, fontWeight: 500, flex: '1 0 100%', color: isMobile ? '#fff' : 'bg.card', onClick: () => {
                    if (questionGA)
                        questionGA();
                    onToggle();
                }, alignItems: 'flex-start', lineHeight: '18px', children: [_jsx(MenuCloseIcon, { color: 'readable.pageButton', w: 18, h: 18, transform: isOpen ? '' : 'rotate(-90deg)' }), _jsx(Box, { ml: 4, lineHeight: 'normal', children: buttonText })] }), _jsx(Collapse, { color: 'bg.card', in: isOpen, children: children })] }));
};
