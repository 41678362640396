import { Icon, IconProps } from '@totejs/icons';

const ProfileImage = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_484_15199)">
        <g clipPath="url(#clip1_484_15199)">
          <rect x="19.9167" y="-4.75" width="12.3333" height="12.3333" fill="#8D26C8" />
        </g>
        <g clipPath="url(#clip2_484_15199)">
          <rect x="-5.75" y="-5.75" width="40" height="40" rx="20" fill="#0D5245" />
          <rect x="7.58331" y="7.58325" width="13.3333" height="13.3333" fill="#6ACAD7" />
          <rect x="20.9167" y="20.9167" width="13.3333" height="13.3333" fill="#0C786B" />
          <rect x="7.58331" y="20.9167" width="13.3333" height="13.3333" fill="#4CBCC4" />
          <rect x="-5.75" y="7.58325" width="13.3333" height="13.3333" fill="#377E94" />
          <rect x="20.9167" y="-5.75" width="13.3333" height="13.3333" fill="#0C786B" />
        </g>
        <circle cx="16" cy="16" r="17.5" stroke="#115A25" />
        <g clipPath="url(#clip3_484_15199)">
          <g clipPath="url(#clip4_484_15199)">
            <rect x="19.9167" y="-4.75" width="12.3333" height="12.3333" fill="#8D26C8" />
          </g>
          <g clipPath="url(#clip5_484_15199)">
            <rect x="-5.75" y="-5.75" width="40" height="40" rx="20" fill="#2467A5" />
            <rect x="7.58331" y="7.58325" width="13.3333" height="13.3333" fill="#54AAF9" />
            <rect x="20.9167" y="20.9167" width="13.3333" height="13.3333" fill="#3A7CDE" />
            <rect x="7.58331" y="20.9167" width="13.3333" height="13.3333" fill="#4399FF" />
            <rect x="-5.75" y="7.58325" width="13.3333" height="13.3333" fill="#5691E9" />
            <rect x="20.9167" y="-5.75" width="13.3333" height="13.3333" fill="#3A7CDE" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_484_15199">
          <rect x="0.25" y="0.25" width="31.5" height="31.5" rx="15.75" fill="white" />
        </clipPath>
        <clipPath id="clip1_484_15199">
          <rect x="-4.75" y="-4.75" width="37" height="37" rx="18.5" fill="white" />
        </clipPath>
        <clipPath id="clip2_484_15199">
          <rect x="-5.75" y="-5.75" width="40" height="40" rx="20" fill="white" />
        </clipPath>
        <clipPath id="clip3_484_15199">
          <rect x="0.25" y="0.25" width="31.5" height="31.5" rx="15.75" fill="white" />
        </clipPath>
        <clipPath id="clip4_484_15199">
          <rect x="-4.75" y="-4.75" width="37" height="37" rx="18.5" fill="white" />
        </clipPath>
        <clipPath id="clip5_484_15199">
          <rect x="-5.75" y="-5.75" width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default ProfileImage;
