import { Modal, Button, Link, Flex, Box } from '@totejs/uikit';
import styled from '@emotion/styled';
import { reportEvent, GA_MAP } from '@op-bridge/bridge-core';

import ComboLogo from '../svgIcon/ComboLogo';

interface PolicyProps {
  modalOpen: boolean;
  handleCookie: () => void;
  handlePolicyOpen: () => void;
}

export const PolicyModal = (props: PolicyProps) => {
  const { handleCookie, modalOpen, handlePolicyOpen } = props;

  return (
    <Container
      isOpen={modalOpen}
      onClose={handlePolicyOpen}
      margin={0}
      closeOnOverlayClick={false}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <Flex justifyContent={'center'} mb={16} mt={24}>
        <ComboLogo width={100} height={100}></ComboLogo>
      </Flex>
      <Title>🥳 Welcome to the Combo Bridge</Title>
      <List>
        <li>
          This is beta software subject to change. For guidance, refer to our{' '}
          <Link href="https://docs.nodereal.io/docs/bridge-guides" target={'_blank'}>
            documentation
          </Link>
          .
        </li>
        <li>
          Agree to our{' '}
          <Link
            onClick={() => {
              reportEvent({
                name: GA_MAP.terms,
                data: {
                  name: 'Terms',
                },
              });
            }}
            href="https://combonetwork.io/terms"
            target={'_blank'}
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            onClick={() => {
              reportEvent({
                name: GA_MAP.terms,
                data: {
                  name: 'Terms',
                },
              });
            }}
            href="https://combonetwork.io/privacy-policy"
            target={'_blank'}
          >
            Privacy Policy
          </Link>{' '}
          by clicking below.
        </li>
      </List>

      <Box mb={24}>
        <Button
          width={'100%'}
          onClick={() => {
            reportEvent({
              name: GA_MAP.terms,
              data: {
                name: 'Terms',
              },
            });
            handleCookie();
          }}
        >
          Agree
        </Button>
      </Box>
    </Container>
  );
};

const List = styled.ul`
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  > li {
    margin-left: 24px;
    margin-bottom: 12px;
    color: ${(props: any) => props.theme.colors.readable?.top.secondary};

    a {
      color: ${(props: any) => props.theme.colors.readable?.link};
    }
  }
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 24px;
`;

const Container = styled(Modal)`
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  color: ${(props: any) => props.theme.colors.readable?.normal};
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  .ui-modal-content {
    padding: 24px;
    width: 568px;
  }
`;
