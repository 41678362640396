import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../base/request';
export const useGetPrice = (symbol = 'BNB') => {
    return useQuery(['tokenPrice', symbol], async () => {
        return (await apiClient.get(`/token/getPrice?symbol=${symbol}`)).data?.data;
    }, {
        enabled: !!symbol && symbol !== 'undefined',
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 60,
        refetchOnMount: false,
        retry: false,
    });
};
