import { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { getCookie, setCookie } from 'cookies-next';
import { mobileMedia, useModal } from '@op-bridge/bridge-core';

import Header from './Header';
import Footer from './Footer';
import { PolicyModal } from './PolicyModal';
import { batchUpdate } from '../../utils';

export default function Layout({ page }: { page: React.ReactNode }) {
  const cookie = getCookie('term-condition');

  const modalData = useModal();
  const modalOpen = modalData.modalState?.open;
  const [policyOpen, setPolicyOpen] = useState(!cookie);
  const [blurBg, setBlurBg] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      setBlurBg(true);
    } else {
      setBlurBg(false);
    }
  }, [modalOpen]);

  const handlePolicyOpen = useCallback(() => {
    batchUpdate(() => {
      modalData.modalDispatch({ type: 'CLOSE' });
      setPolicyOpen(false);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set policy cookie
  const handleCookie = useCallback(() => {
    batchUpdate(() => {
      setCookie('term-condition', 'true');
      modalData.modalDispatch({ type: 'CLOSE' });
      setPolicyOpen(false);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className={blurBg ? 'open' : 'close'}>
        <Header />
        <Main>{page}</Main>
        <Footer />
      </Container>
      <PolicyModal
        modalOpen={policyOpen}
        handleCookie={handleCookie}
        handlePolicyOpen={handlePolicyOpen}
      />
    </>
  );
}
const Main = styled.main`
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 64px;
  margin-bottom: 32px;
  background: ${(props: any) => props.theme.colors.bg?.bottom};
  ${mobileMedia} {
    display: block;
    width: 100%;
    margin: 68px auto 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;
