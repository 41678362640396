import * as ethers from 'ethers';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import BN from 'bn.js';
dayjs.extend(utc);
const ZERO = new BN(0);
export const getEtherFormatValue = async (balance) => {
    try {
        return await ethers.utils.formatEther(balance);
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return null;
    }
};
export const batchUpdate = (fn) => {
    ReactDOM.unstable_batchedUpdates(fn);
};
export const trimLongStr = (str, maxLength = 14, headLen = 4, footLen = 8) => {
    if (!str) {
        return '';
    }
    if (str.length > maxLength) {
        const head = str.substring(0, headLen);
        const foot = str.substring(str.length - footLen, str.length);
        return `${head}...${foot}`;
    }
    return str;
};
export const formatTime = (date) => {
    if (!date) {
        return '';
    }
    return dayjs(date)?.utc().format('YYYY-M-DD hh:mm:ss A');
};
export const removeTrailingZero = (str) => {
    const newStr = str.replace(/0+$/, '');
    return newStr.endsWith('.') ? newStr.slice(0, -1) : newStr;
};
export const getTxFee = (gasPrice, gasUsed, decimalLength = 8) => {
    if (!gasPrice || !gasUsed) {
        return '0';
    }
    let valStr = '';
    const a = new BN(gasPrice);
    const b = new BN(gasUsed);
    const bn = a.mul(b);
    valStr = divide10Exp(bn, 18);
    return removeTrailingZero(Number(valStr).toFixed(decimalLength));
};
export const divide10Exp = (origin, pow) => {
    if (origin.eq(ZERO)) {
        return '0';
    }
    const divisor = new BN(10).pow(new BN(pow));
    if (origin.lt(divisor)) {
        const str = origin.toString(10, pow);
        return '0.' + removeTrailingZero(str);
    }
    else {
        const mod = origin.mod(divisor);
        const intPartStr = origin.div(divisor).toString();
        if (mod.eq(ZERO)) {
            return intPartStr;
        }
        else {
            return intPartStr + '.' + removeTrailingZero(mod.toString(10, pow));
        }
    }
};
const seperateStr = (str) => {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatNum = (num) => {
    if (BN.isBN(num)) {
        const str = num.toString(10);
        return seperateStr(str);
    }
    return Number(num).toLocaleString('fullwide', {
        maximumFractionDigits: 8,
    });
};
export const addDataIntoCache = async (url, rawData) => {
    const nowTime = Date.now();
    const data = new Response(JSON.stringify({ ...rawData, time: nowTime }));
    if ('caches' in window) {
        try {
            const cacheStorage = await caches.open('historyCache');
            const cachedResponse = await cacheStorage.match(url);
            if (cachedResponse) {
                let currentCache = await cachedResponse.json();
                let newCache = { ...currentCache, ...rawData, time: nowTime };
                const data = new Response(JSON.stringify(newCache));
                cacheStorage.put(url, data);
            }
            else {
                cacheStorage.put(url, data);
            }
        }
        catch (e) {
            //eslint-disable-next-line no-console
            console.log(e);
        }
    }
};
export const getDataFromCache = async (url) => {
    // Opening that particular cache
    const nowTime = Date.now();
    try {
        const cacheStorage = await caches.open('historyCache');
        if (!cacheStorage) {
            return null;
        }
        let data = null;
        const cachedResponse = await cacheStorage.match(url);
        if (cachedResponse) {
            data = await cachedResponse.json();
            if (data?.time) {
                if (nowTime - data?.time > 60 * 3 * 1000) {
                    // remove old cache
                    cacheStorage?.delete(url);
                    return null;
                }
            }
            else {
                cacheStorage?.delete(url);
                return null;
            }
        }
        return data;
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return null;
    }
};
export const deleteDataFromCache = async (url, key) => {
    // Opening that particular cache
    try {
        const cacheStorage = await caches.open('historyCache');
        const cachedResponse = await cacheStorage.match(url);
        if (cachedResponse) {
            let data = await cachedResponse.json();
            if (data[key]) {
                delete data[key];
                const newData = new Response(JSON.stringify(data));
                cacheStorage.put(url, newData);
            }
        }
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
