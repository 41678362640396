import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { useMemo, useRef } from 'react';
import { useVirtual } from '@tanstack/react-virtual';
import { Box, Flex, Loading, Table } from '@totejs/uikit';
import { FileIcon } from '../svgIcon/FileIcon';
import { useResponsive } from '../../hooks';
const StickyHeaderTable = (props) => {
    const { data, columns, pageSize, total, tableHeight, cellHeight, headerHeight, isLoading, paddingBottom, emptyText, topContent, bottomContent, type, } = props;
    const tableContainerRef = useRef(null);
    const table = useReactTable({
        data: data ? data : [],
        columns: columns,
        enableColumnResizing: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
    });
    const { rows } = table?.getRowModel();
    const { isMobile } = useResponsive();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length || 0,
        overscan: 30,
    });
    const { virtualItems: virtualRows } = rowVirtualizer;
    const tableHeader = useMemo(() => {
        return table.getHeaderGroups() || [];
    }, [table]);
    return (_jsxs(Box, { boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)', borderRadius: '16px', background: 'bg.table', children: [topContent && total > pageSize && (_jsx(Box, { marginX: ['0', '16px'], borderBottom: '1px solid readable.border', children: topContent })), _jsx(Box, { className: "container", height: !isMobile ? tableHeight : 'auto', marginX: ['0', '16px', '16px'], border: "none", backgroundColor: 'bg.table', overflowX: 'auto', borderRadius: [0, 16, 16], ref: tableContainerRef, children: !isMobile ? (_jsxs(Box, { as: "table", sx: {
                        tableLayout: 'fixed',
                        width: '100%',
                        th: {
                            h: headerHeight || 44,
                            fontWeight: 500,
                            fontSize: 12,
                            lineHeight: '18px',
                        },
                        'td, th': {
                            px: 16,
                            background: 'bg.table',
                        },
                    }, children: [_jsx(Box, { as: "thead", margin: "0", children: tableHeader?.map((headerGroup) => (_jsx(Box, { as: "tr", children: headerGroup.headers?.map((header) => {
                                    return (_jsx(Box, { as: "th", position: 'sticky', zIndex: 1, top: "0", colSpan: header.colSpan, width: header.getSize(), textAlign: "left", height: '44px', color: 'readable.secondary', background: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(15px)', children: flexRender(header.column.columnDef.header, header.getContext()) }, header.id));
                                }) }, headerGroup.id))) }), data && data?.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs(Box, { as: "tbody", children: [_jsx(Box, { as: "tr", children: columns?.map((column, index) => {
                                                return (_jsx(Box, { as: "td", sx: { height: `1px`, borderBottom: '1px solid readable.border' } }, index));
                                            }) }), virtualRows?.map((virtualRow, index) => {
                                            const row = rows[virtualRow.index];
                                            return (_jsx(TrBox, { as: "tr", color: "readable.normal", borderBottom: '1px solid readable.border', className: index + 1 === virtualRows?.length ? 'rm-bottom' : '', children: row.getVisibleCells()?.map((cell) => {
                                                    return (_jsx(Box, { as: "td", height: cellHeight || 56, textAlign: 'left', children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id));
                                                }) }, row.id));
                                        })] }), paddingBottom && paddingBottom > 0 && (_jsx(Box, { as: "tr", children: _jsx("td", { style: { height: `${paddingBottom}px` } }) }))] })) : isLoading ? (_jsx(Box, { as: "tbody", children: _jsx(Box, { as: "tr", children: _jsx("td", { colSpan: columns.length || 1, children: _jsx(Flex, { flex: 1, alignItems: 'center', justifyContent: 'center', children: _jsx(Table, { background: 'bg.table', border: 'none', columns: columns, data: data }) }) }) }) })) : (_jsx(Box, { as: "tbody", children: _jsx(Box, { as: "tr", children: _jsx(Box, { as: "td", colSpan: columns.length || 1, style: { padding: 0 }, children: _jsxs(Flex, { flex: 1, alignItems: 'center', justifyContent: 'center', borderTop: '1px solid readable.border', flexDirection: 'column', flexWrap: 'wrap', mb: 138, children: [_jsx(Flex, { alignItems: 'center', justifyContent: 'center', children: _jsx(FileIcon, { width: 120, height: 120, mt: 94, mb: 16 }) }), _jsx(EmptyText, { children: emptyText || 'no data found' })] }) }) }) }))] })) : (
                // isMobile
                _jsx(Box, { children: data && data?.length > 0 ? (virtualRows?.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        return (_jsx(Box, { display: 'flex', flexDirection: type === 'withdraw' ? 'row' : 'column', color: "readable.normal", borderBottom: '1px solid readable.border', className: tableHeight === 437 ? '' : 'rm-bottom', paddingY: 16, height: type === 'withdraw' ? 95 : 89, flexWrap: type === 'withdraw' ? 'nowrap' : 'wrap', justifyContent: 'space-between', children: type === 'withdraw' ? (_jsxs(_Fragment, { children: [_jsx(Box, { maxW: 175, children: row.getVisibleCells()?.map((cell, index) => {
                                            // amount 0
                                            // init tx 1
                                            // time 2
                                            // status 3
                                            // action 4
                                            if (index === 3 || index === 4)
                                                return null;
                                            return (_jsx(Flex, { children: _jsxs(Box, { display: 'flex', h: index === 0 ? 18 : index === 1 || index === 2 ? 15 : 30, mb: index === 0 ? 10 : 0, w: index === 0 ? 151 : 192, pl: index !== 0 ? 24 : 0, justifyContent: 'space-between', children: [index === 1 && (_jsx(Box, { fontSize: 12, fontWeight: 500, lineHeight: 'normal', color: 'readable.pageButton', mr: 4, children: "Transaction:" })), _jsx(Box, { textAlign: 'left', fontSize: 12, fontWeight: 500, lineHeight: 'normal', w: '100%', whiteSpace: 'nowrap', color: index !== 2 ? 'readable.normal' : 'readable.pageButton', display: 'flex', justifyContent: index >= 3 ? 'flex-end' : 'flex-start', children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)] }) }, index));
                                        }) }), _jsx(Box, { maxW: 155, children: row.getVisibleCells()?.map((cell, index) => {
                                            // amount 0
                                            // init tx 1
                                            // time 2
                                            // status 3
                                            // action 4
                                            if (index === 0 || index === 1 || index === 2)
                                                return null;
                                            return (_jsx(Flex, { children: _jsxs(Box, { display: 'flex', h: index === 3 ? 'auto' : 30, mb: index === 3 ? 10 : 0, w: 192, pl: 24, justifyContent: 'space-between', children: [index !== 0 && index !== 2 && (_jsxs(Box, { fontSize: 12, fontWeight: 500, lineHeight: 'normal', color: 'readable.pageButton', children: [flexRender(tableHeader[0].headers[index].column.columnDef.header, tableHeader[0].headers[index].getContext()), ' ', index === 1 && ': '] })), _jsx(Box, { textAlign: 'left', fontSize: 12, fontWeight: 500, lineHeight: 'normal', w: '100%', whiteSpace: 'nowrap', display: 'flex', justifyContent: index >= 3 ? 'flex-end' : 'flex-start', children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id)] }) }, index));
                                        }) })] })) : (
                            // deposit
                            _jsx(_Fragment, { children: row.getVisibleCells()?.map((cell, index) => {
                                    return (_jsx(Box, { h: index === 0 ? 18 : index !== 3 ? 15 : 24, w: index !== 3 ? 178 : 'auto', pl: index !== 0 ? 24 : 0, children: _jsxs(Box, { textAlign: 'left', fontSize: 12, fontWeight: 500, lineHeight: 'normal', w: '100%', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', gap: 4, gridGap: 4, justifyContent: index === 3 ? 'flex-end' : 'flex-start', children: [index === 2 && _jsx("span", { children: "Txn: " }), flexRender(cell.column.columnDef.cell, cell.getContext())] }, cell.id) }));
                                }) })) }, row.id));
                    })) : isLoading ? (_jsx(Box, { height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', children: _jsx(Loading, {}) })) : (_jsx(Box, { children: _jsxs(Flex, { flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', mb: 138, children: [_jsx(Flex, { alignItems: 'center', justifyContent: 'center', children: _jsx(FileIcon, { width: 120, height: 120, mt: 94, mb: 16 }) }), _jsx(EmptyText, { children: emptyText || 'no data found' })] }) })) })) }), bottomContent && data && total > pageSize && (_jsx(Box, { marginX: ['0', '16px'], children: bottomContent }))] }));
};
export default StickyHeaderTable;
const EmptyText = styled.div `
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.readable.secondary};
`;
const TrBox = styled(Box) `
  &.rm-bottom:last-child {
    border-bottom: none;
  }
`;
