import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Modal, ModalCloseButton } from '@totejs/uikit';
import styled from '@emotion/styled';
import { mobileMedia } from '../../hooks';
import WaitingConfirm from '../svgIcon/WaitingConfirm';
export const WaitingForConfirmation = (props) => {
    const { isOpen, handleOpen, theme = 'dark', titleStyle } = props;
    const finalTitleStyle = { fontWeight: 'Space Grotesk', ...titleStyle };
    return (_jsxs(Container, { isOpen: isOpen, modaltheme: theme, onClose: () => handleOpen(false), margin: 0, overlayProps: {
            backdropFilter: 'blur(10px)',
        }, children: [_jsx(ModalCloseButton, { right: 24, top: 16, width: 24, height: 24, onClick: () => handleOpen(false), color: 'bg.card' }), _jsxs(Content, { children: [_jsx(WaitingConfirm, { width: 80, height: 80, mb: [16, 0, 0] }), _jsxs(Title, { style: finalTitleStyle, modaltheme: theme, children: [' ', "Waiting for Confirmation"] }), _jsx(Subtitle, { children: " Confirm this transaction in your wallet." })] })] }));
};
const Container = styled(Modal) `
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 542px;
    background: ${(props) => props.modaltheme === 'dark'
    ? props.theme.colors.readable.normal
    : props.theme.colors.bg?.card};
    color: ${(props) => props.modaltheme === 'dark'
    ? props.theme.colors.bg?.card
    : props.theme.colors.readable.normal};
    ${mobileMedia} {
      padding: 24px;
      max-width: 542px !important;
      margin: 0 16px;
      width: 343px;
    }
  }
  .ui-close-button {
    color: ${(props) => props.theme.colors.readable.secondary};
  }
`;
const Content = styled(Flex) `
  flex-direction: column;
  gap: 16px;
  align-items: center;
  ${mobileMedia} {
    gap: 0;
  }
`;
const Title = styled.div `
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
  color: ${(props) => props.modaltheme === 'dark' ? props.theme.colors.bg?.card : props.theme.colors.readable.normal};
  ${mobileMedia} {
    margin-bottom: 16px;
  }
`;
const Subtitle = styled.div `
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props) => props.theme.colors.readable.pageButton};
  ${mobileMedia} {
    font-size: 18px;
    text-align: center;
  }
`;
