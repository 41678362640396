import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../base/request';
import { defaultSetting } from './default';
export const useDepositHistory = (address, page, pageSize = 20) => {
    return useQuery(['depositTx', address, page, pageSize], async () => {
        return (await apiClient.get(`tx/getAssetTransferByAddress?address=${address}&pageSize=${pageSize}&page=${page}&type=deposit`)).data.data;
    }, {
        ...defaultSetting,
        enabled: !!address,
        staleTime: 1000 * 5,
        keepPreviousData: true,
    });
};
