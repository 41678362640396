import { jsx as _jsx } from "react/jsx-runtime";
import { Button, StateModal } from '@totejs/uikit';
import styled from '@emotion/styled';
import { mobileMedia } from '../../hooks/useResponsive';
import { GA_MAP, reportEvent } from '../../utils/ga';
export const TxError = (props) => {
    const { isOpen, handleOpen, errorCode, clearInput, title, theme = 'dark' } = props;
    return (_jsx(Container, { modaltheme: theme, isOpen: isOpen, onClose: () => {
            handleOpen();
            if (clearInput)
                clearInput();
        }, margin: 0, variant: "error", title: title, description: `Error code: ${errorCode || ''}`, overlayProps: {
            backdropFilter: 'blur(10px)',
        }, children: _jsx(AgainButton, { onClick: () => {
                reportEvent({ name: GA_MAP.tryAgain, data: { name: 'Try Again' } });
                if (clearInput)
                    clearInput();
                handleOpen();
            }, children: "Try Again" }) }));
};
const Container = styled(StateModal) `
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 568px;
    max-height: 400px;
    overflow-y: auto;
    background: ${(props) => props.modaltheme === 'dark'
    ? props.theme.colors.readable.normal
    : props.theme.colors.bg?.card};
    color: ${(props) => props.modaltheme === 'dark'
    ? props.theme.colors.bg?.card
    : props.theme.colors.readable.normal};
    ${mobileMedia} {
      margin: 0 16px;
      width: 343px;
    }
  }
  .ui-modal-body {
    font-size: 18px;
    margin-top: 16px;
    color: ${(props) => props.theme.colors.readable.pageButton};
  }
  .ui-state-modal-title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 16px;
  }
  .ui-close-button {
    color: ${(props) => props.theme.colors.readable.secondary};
  }
`;
const AgainButton = styled(Button) `
  width: 100%;
  height: 48px;
  color: ${(props) => props.theme.colors?.bg.card};
`;
