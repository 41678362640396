import React from 'react';
// import { getCookie } from 'cookies-next';
// check whether coolie exist
// const cookie = getCookie('term-condition');
export let initialState;
// if (cookie) {
initialState = {
    open: false,
};
export const ModalContext = React.createContext(null);
ModalContext.displayName = 'ModalContext';
export const ModalReducer = (initialState, action) => {
    switch (action.type) {
        case 'OPEN':
            return {
                open: true,
            };
        case 'CLOSE':
            return {
                open: false,
            };
        default:
            return initialState;
    }
};
export * from './Provider';
