import { useWeb3 } from './useWeb3';
import tokenABI from '../base/tokenABI.json';
export const useCheckErc20TokenMatch = (rpcURL) => {
    const web3 = useWeb3(rpcURL);
    const checkTokenAddress = async (tokenAsset) => {
        const L2TokenContract = new web3.eth.Contract(tokenABI, tokenAsset?.l2Address);
        const l1RemoteTokenAddress = await L2TokenContract.methods.l1Token().call();
        const tokenSymbol = await L2TokenContract.methods.symbol().call();
        //eslint-disable-next-line no-console
        console.log('l1 remote address:', l1RemoteTokenAddress, 'token L1 address:', tokenAsset?.l1Address, 'contract token symbol:', tokenSymbol);
        // check the following conditions:
        // 1. remote l1 token address matches the token l1 address
        // 2. token symbol matches the contract token symbol
        // 3. token decimals is 18
        if (l1RemoteTokenAddress?.toLowerCase() === tokenAsset?.l1Address?.toLowerCase() &&
            (tokenSymbol === tokenAsset?.symbol ||
                (tokenAsset?.symbol === 'BTCB' && tokenSymbol === 'BTC')) &&
            tokenAsset?.decimals === '18') {
            return true;
        }
        else {
            return false;
        }
    };
    return {
        checkTokenAddress,
    };
};
