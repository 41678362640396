import { ethers, providers } from 'ethers';
import { useCallback, useMemo } from 'react';
import { useAccount, useSigner } from 'wagmi';
export function walletClientToSigner(walletClient, address) {
    if (!walletClient)
        return null;
    const provider = new providers.Web3Provider(walletClient?.provider?.provider);
    const signer = provider.getSigner(address);
    return signer;
}
/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId } = {}) {
    const { address } = useAccount();
    const { data: walletClient } = useSigner({ chainId });
    return useMemo(() => (walletClient && address ? walletClientToSigner(walletClient, address) : undefined), [walletClient, address]);
}
export const useGetSigners = (l2Rpc) => {
    const { connector } = useAccount();
    const getSigners = useCallback((address) => {
        let l1RpcProvider = null;
        const l2RpcProvider = new ethers.providers.JsonRpcProvider(l2Rpc, 'any');
        if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
            l1RpcProvider = new ethers.providers.Web3Provider(window?.ethereum);
            if (connector?.name?.toLocaleLowerCase() === 'trust wallet') {
                // @ts-ignore
                l1RpcProvider = new ethers.providers.Web3Provider(window?.trustWallet);
            }
            return [l1RpcProvider.getSigner(address), l2RpcProvider.getSigner(address)];
        }
        return [];
    }, [connector, l2Rpc]);
    return { getSigners };
};
