import styled from '@emotion/styled';
import { Tabs, TabList, Tab } from '@totejs/uikit';
import { mobileMedia } from '@op-bridge/bridge-core';
interface HeaderTabProps {
  tabs: { key?: string; value?: string }[];
  activeKey: string;
  onTabChange: (page: number | string) => void;
}

export const HeaderTab = (props: HeaderTabProps) => {
  const { tabs, activeKey, onTabChange } = props;

  return (
    <CustomTabs activeKey={activeKey} onChange={onTabChange}>
      <CustomTabList>
        {tabs.map((tab: { key?: string; value?: string }, index: number) => {
          if (tab?.key) {
            return (
              <StyledTab key={index} tabKey={tab.key}>
                {tab.value}
              </StyledTab>
            );
          } else {
            return null;
          }
        })}
      </CustomTabList>
    </CustomTabs>
  );
};

const CustomTabs = styled(Tabs)`
  width: 100%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  max-width: 231px;
  margin-top: -8px;
  height: 64px;
  ${mobileMedia} {
    height: 40px;
    position: static;
    margin: 0px auto;
    max-width: 100%;
    transform: none;
  }
`;

const CustomTabList = styled(TabList)`
  gap: 32px;
  justify-content: center;
  border-bottom: none;
  height: 64px;
  ${mobileMedia} {
    gap: 0;
    height: 40px;
    width: 100%;
    width: 230px;
    justify-content: center;
    margin: 0 auto;
    & > div:not(:first-of-type) {
      margin-left: 30px;
    }
  }
`;

const StyledTab = styled(Tab)`
  font-family: Inter;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: -4px;
  color: ${(props: any) => props.theme.colors.readable.normal};
  border-bottom: 4px solid transparent;
  &[data-selected] {
    font-weight: 700;
    border-bottom: 4px solid ${(props: any) => props.theme.colors.scene.primary.normal};
    color: ${(props: any) => props.theme.colors.readable.normal};
    &:hover {
      color: ${(props: any) => props.theme.colors.readable.normal};
    }
  }
  &:not([data-selected]):hover {
    font-weight: 500;
    border-bottom: 4px solid ${(props: any) => props.theme.colors.scene.primary.normal};
    color: ${(props: any) => props.theme.colors.readable.normal};
  }
  ${mobileMedia} {
    height: 40px;
  }
`;
