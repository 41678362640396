import { Flex } from '@totejs/uikit';
import styled from '@emotion/styled';
import { SocialLinks, reportEvent, GA_MAP } from '@op-bridge/bridge-core';

const Footer = () => {
  return (
    <StyledFooter>
      <SocialLinks
        theme="light"
        toolTipsStyle={{
          color: 'readable.normal',
        }}
        discordLink="https://discord.com/invite/ADj3BsXWvD "
        telegramLink="https://t.me/COMBONetworkio"
        githubLink="https://github.com/node-real/combo-optimism"
        twitterLink="https://twitter.com/combonetworkio"
        onDiscordClick={() => {
          reportEvent({
            name: GA_MAP.footerDiscord,
            data: { name: 'Footer Discord Link Click' },
          });
        }}
        onGithubClick={() => {
          reportEvent({
            name: GA_MAP.footerGithub,
            data: { name: 'Footer GitHub Link Click' },
          });
        }}
        onTelegramClick={() => {
          reportEvent({
            name: GA_MAP.footerTelegram,
            data: { name: 'Footer Telegram Link Click' },
          });
        }}
        onTwitterClick={() => {
          reportEvent({
            name: GA_MAP.footerTwitter,
            data: { name: 'Footer Twitter Link Click' },
          });
        }}
        onYoutubeClick={() => {
          reportEvent({
            name: GA_MAP.footerYoutube,
            data: { name: 'Footer YouTube Link Click' },
          });
        }}
      />
      <FlexContainer justifyContent={'center'} marginTop={3}>
        <CopyRight>
          © {new Date().getFullYear()}{' '}
          <Link href="https://nodereal.io/" target="_blank" rel="noreferrer">
            NodeReal
          </Link>
          . All rights reserved.
        </CopyRight>
      </FlexContainer>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  width: 100%;
`;

const CopyRight = styled.div`
  padding: 13px;
  text-align: center;
  color: ${(props: any) => props.theme.colors.readable?.secondary};
`;

const FlexContainer = styled(Flex)`
  background-color: ${(props: any) => props.theme.colors.bg?.bottom};
`;

const Link = styled.a`
  text-decoration: underline;
  &:hover {
    color: ${(props: any) => props.theme.colors.scene.primary.normal};
  }
`;
