import { useCallback, useState } from 'react';
import { useModal } from './useModal';
export const usePending = () => {
    const [showPending, setShowPending] = useState(true);
    const modalData = useModal();
    // const { isMobile } = useResponsive();
    const handlePendingToggle = useCallback((show) => {
        if (!!show) {
            // if (!isMobile) {
            //   // document.body.style.overflow = 'hidden';
            // }
            setShowPending(show);
        }
        else {
            setShowPending((preState) => !preState);
            document.body.style.overflow = '';
        }
    }, []);
    const handlePendingShow = useCallback((show) => {
        if (show) {
            handlePendingToggle(true);
            modalData.modalDispatch({ type: 'OPEN' });
        }
        else {
            handlePendingToggle(false);
            modalData.modalDispatch({ type: 'CLOSE' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { showPending, handlePendingToggle, handlePendingShow };
};
