import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Flex, toast } from '@totejs/uikit';
import { useAccount, useSwitchNetwork } from 'wagmi';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { ColoredWarningIcon } from '@totejs/icons';
import { mobileMedia } from '../../hooks';
export const SwitchNetworkButton = (props) => {
    const { id, onSwitchHandler, isWithdraw } = props;
    const { connector } = useAccount();
    const { chains, isLoading, switchNetwork } = useSwitchNetwork({
        onError: (err) => {
            toast.error({
                description: err.message,
            });
        },
    });
    const handleSwitchNetwork = useCallback(() => {
        try {
            if (id) {
                switchNetwork?.(id);
            }
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }, [id, switchNetwork]);
    const targetChain = chains?.filter((chain) => {
        return chain.id === id;
    });
    return targetChain && targetChain?.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs(SwitchButton, { disabled: !id || (connector?.id !== 'walletConnect' && isLoading), onClick: () => {
                    handleSwitchNetwork();
                    if (onSwitchHandler)
                        onSwitchHandler();
                }, children: ["Switch to ", targetChain[0]?.name] }), _jsxs(Warning, { children: [_jsx(ColoredWarningIcon, { width: 16, height: 16, mr: 4, color: "scene.orange.normal" }), " Please switch to ", targetChain[0]?.name, " to continue the ", isWithdraw ? 'withdrawal' : 'transfer', "."] })] })) : null;
};
const SwitchButton = styled(Button) `
  width: 100%;
  background: ${(props) => props.theme.colors.scene.orange.normal};
  &:hover {
    background: ${(props) => props.theme.colors.scene.orange.active};
  }
  ${mobileMedia} {
    white-space: nowrap;
  }
`;
const Warning = styled(Flex) `
  flex-direction: row;
  margin-top: 8px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props) => props.theme.colors.readable.secondary};
`;
