import { ethers } from 'ethers';
import { useWeb3 } from './useWeb3';
import { L2_RPC_URL, OracleGetPriceAddress } from '../env';
import ABI from '../base/GasPriceOracle.json';
const txData = {
    // https://opbnbscan.com/tx/0xd3acfa86854b5cc0b5a6650208bc046ed5e785e7cf8db24633846a4ed7c3de85
    nonce: '72',
    gasPrice: ethers.utils.parseUnits('0.000010008', 'gwei'),
    gasLimit: '120517',
    to: '0x4200000000000000000000000000000000000010',
    value: ethers.utils.parseEther('0.00002'),
    data: '0x32b7006d000000000000000000000000deaddeaddeaddeaddeaddeaddeaddeaddead0000000000000000000000000000000000000000000000000000000012309ce54000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000800000000000000000000000000000000000000000000000000000000000000000', // data/payload
};
export const useGetL1DataFee = () => {
    const web3 = useWeb3(L2_RPC_URL);
    // Get paused variable
    const loadL1DataFee = async () => {
        try {
            //  create unsigned tx
            const tx = {
                nonce: txData.nonce,
                gasPrice: txData.gasPrice,
                gasLimit: txData.gasLimit,
                to: txData.to,
                value: txData.value,
                data: txData.data,
            };
            const rlpEncodedTx = ethers.utils.RLP.encode([
                ethers.utils?.hexlify(Number(tx.nonce)),
                ethers.utils?.hexlify(Number(tx.gasPrice)),
                ethers.utils?.hexlify(Number(tx.gasLimit)),
                tx.to.toLowerCase(),
                ethers.utils?.hexlify(Number(tx.value)),
                tx.data,
            ]);
            const contract = new web3.eth.Contract(ABI, OracleGetPriceAddress, {
                from: '0x0000000000000000000000000000000000000000',
            });
            const l1DataFee = await contract.methods.getL1Fee(rlpEncodedTx).call();
            return l1DataFee;
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };
    return {
        loadL1DataFee,
    };
};
