import { useRouter } from 'next/router';
import { useCallback, useState, useEffect } from 'react';
import { batchUpdate } from '../utils';
export const usePagination = (queryKey = 'p') => {
    const router = useRouter();
    const queryPage = Number(router?.query?.[queryKey]);
    const [page, setPage] = useState(isNaN(queryPage) || queryPage < 1 ? 1 : queryPage);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (isNaN(queryPage)) {
            setPage(1);
        }
        else {
            setPage(queryPage);
        }
    }, [queryPage]);
    const handlePageChange = useCallback((page) => {
        setLoading(true);
        const newPage = page;
        router
            .replace({
            query: { ...router.query, [queryKey]: newPage },
        }, undefined, { scroll: false })
            .then(() => {
            batchUpdate(() => {
                setPage(newPage);
                setLoading(true);
            });
        });
    }, [queryKey, router]);
    return { handlePageChange, page, loading };
};
