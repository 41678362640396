import { jsx as _jsx } from "react/jsx-runtime";
import { useAccount, useBalance } from 'wagmi';
import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Box, Flex } from '@totejs/uikit';
import { mobileMedia, useGetSigners } from '../hooks';
import { removeTrailingZero, getEtherFormatValue, batchUpdate } from '../utils';
import { Loading } from './Loading';
export const TokenBalance = (props) => {
    const { tokenName, type, loadingComponent, l1ChainId, l2ChainId, l1Rpc, l2Rpc, assetList } = props;
    const { isConnected, address } = useAccount();
    const { getSigners } = useGetSigners(l2Rpc);
    const [isLoadingBalance, setIsLoadingBalance] = useState(false);
    const [isLoadingBnbBalance, setIsLoadingBnbBalance] = useState(false);
    const { data: tokenBalance, isLoading: tokenBalanceLoading } = useBalance({
        address: address,
        chainId: type === 'deposit' ? Number(l1ChainId) : Number(l2ChainId),
    });
    const [balance, setBalance] = useState('');
    /**
     * Get ERC20 token balance by user account address
     */
    const getErc20Balance = useCallback(async () => {
        const filteredAsset = assetList?.filter((asset) => asset.name === tokenName);
        if (Array.isArray(filteredAsset) && filteredAsset?.length > 0 && address) {
            const asset = filteredAsset[0];
            // @ts-ignore
            const [l1Signer, l2Signer] = getSigners(address);
            if (type === 'deposit') {
                if (typeof window !== 'undefined' && typeof window?.ethereum !== 'undefined') {
                    const l1RpcProvider = new ethers.providers.JsonRpcProvider(l1Rpc, 'any');
                    const l1Signer = l1RpcProvider.getSigner(address);
                    const l1ERC20 = new ethers.Contract(asset.l1Address, asset.ABI, l1Signer);
                    const l1BalanceObj = await l1ERC20.balanceOf(address);
                    const balance = await getEtherFormatValue(l1BalanceObj);
                    return balance;
                }
                else {
                    return null;
                }
            }
            else {
                const l2ERC20 = new ethers.Contract(asset.l2Address, asset.ABI, l2Signer);
                const l2BalanceObj = await l2ERC20.balanceOf(address);
                const balance = await getEtherFormatValue(l2BalanceObj);
                return balance;
            }
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, tokenName, type]); // end getErc20Balance
    useEffect(() => {
        if (address) {
            try {
                if (tokenName !== 'BNB') {
                    setIsLoadingBalance(true);
                    getErc20Balance()
                        .then((balance) => {
                        if (balance) {
                            batchUpdate(() => {
                                setIsLoadingBalance(false);
                                setBalance(balance.toString());
                            });
                        }
                        else {
                            setIsLoadingBalance(false);
                        }
                    })
                        .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        setIsLoadingBalance(false);
                    });
                }
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, tokenName, type]);
    useEffect(() => {
        if (address) {
            try {
                if (tokenName === 'BNB') {
                    setIsLoadingBnbBalance(true);
                    if (tokenBalance && !tokenBalanceLoading) {
                        setBalance(tokenBalance.formatted);
                    }
                    setIsLoadingBnbBalance(false);
                }
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
                setIsLoadingBnbBalance(false);
            }
        }
    }, [tokenBalance, tokenBalanceLoading, address, tokenName, type]);
    return isConnected ? (!isLoadingBalance && !isLoadingBnbBalance ? (_jsx(Amount, { children: removeTrailingZero(Number(balance).toFixed(8)) })) : (_jsx(Flex, { justifyContent: 'flex-end', alignItems: 'center', minWidth: 125, children: _jsx(Box, { width: 20, height: 20, children: loadingComponent ? loadingComponent : _jsx(Loading, {}) }) }))) : null;
};
const Amount = styled.div `
  width: 125px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.readable.disabled};
  text-align: right;
  ${mobileMedia} {
    width: 154px;
  }
`;
