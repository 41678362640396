import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../base/request';
import { defaultSetting } from './default';
export const useWithdrawHistory = (address, page, pageSize = 20, enabled = true) => {
    return useQuery(['withdrawTx', address, page, pageSize], async () => {
        return (await apiClient.get(`tx/getAssetTransferByAddress?address=${address}&pageSize=${pageSize}&page=${page}&type=withdraw`)).data.data;
    }, {
        ...defaultSetting,
        enabled: !!address && enabled,
        staleTime: 1000 * 5,
        cacheTime: 1000 * 5,
        keepPreviousData: false,
    });
};
