import { useEffect, useState } from 'react';
export const useScrollPosition = (componentPosition) => {
    const [scrollPos, setScrollPos] = useState(false);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', () => {
                if (window.scrollY > componentPosition) {
                    setScrollPos(true);
                }
                else {
                    setScrollPos(false);
                }
            });
        }
    }, [componentPosition]);
    return { scrollPos };
};
