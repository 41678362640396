import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, } from '@totejs/uikit';
import styled from '@emotion/styled';
import { HelpIcon } from '../svgIcon/HelpIcon';
import { Discord } from '../svgIcon/Discord';
export const FAQ = (props) => {
    const { children, faqTitle, closeGA, discordGA, buttonGA } = props;
    return (_jsx(Box, { position: 'fixed', width: '85px', zIndex: 50, bottom: 64, right: 16, margin: ['24px auto 0', 'auto'], children: _jsxs(Popover, { placement: 'top-end', children: [_jsx(PopoverTrigger, { children: _jsxs(Flex, { cursor: 'pointer', color: 'bg.card', fontSize: 16, fontWeight: 700, lineHeight: '20px', padding: '8px 10px', borderRadius: 24, background: 'scene.primary.normal', alignItems: 'center', justifyContent: ['center', 'space-between'], onClick: () => {
                            if (buttonGA)
                                buttonGA();
                        }, _hover: {
                            background: 'scene.primary.active',
                        }, children: [_jsx(HelpIcon, { w: 24, h: 24, mr: 2 }), " Help"] }) }), _jsx(PopoverContent, { padding: 0, background: '#fff', overflow: ['auto', 'hidden'], borderRadius: 8, color: 'bg.card', children: _jsxs(FAQContent, { padding: '13px 0', w: 320, maxH: 630, overflow: 'auto', position: 'relative', children: [_jsxs(Flex, { flex: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center', padding: '0 7px 12px 20px', marginBottom: 0, borderBottom: '1px solid', borderColor: 'readable.top.secondary', children: [_jsx(PopoverHeader, { marginBottom: 0, fontSize: 16, fontWeight: 700, color: 'bg.card', children: faqTitle }), _jsx(PopoverCloseButton, { h: 20, w: 20, top: 14, position: 'absolute', color: 'readable.pageButton', _hover: { color: 'scene.primary.normal' }, onClick: () => {
                                            if (closeGA)
                                                closeGA();
                                        } })] }), _jsx(PopoverBody, { children: children }), _jsxs(PopoverFooter, { borderTop: '1px solid', borderColor: 'readable.top.secondary', marginTop: 12, flexDirection: 'column', alignItems: 'center', padding: '16px', children: [_jsx(Box, { color: 'bg.card', w: 208, textAlign: 'center', children: "Don't hesitate to contact us if you have more questions." }), _jsxs(Button, { maxW: 200, h: 40, padding: '8px 12px', w: '100%', borderRadius: 24, variant: 'ghost', color: 'bg.card', alignItems: 'center', _hover: { background: '#5865F2', color: '#fff', borderColor: '#5865F2' }, onClick: () => {
                                            if (discordGA)
                                                discordGA();
                                            window.open('https://discord.com/invite/bnbchain');
                                        }, children: [_jsx(Discord, { h: 24, w: 24, mr: 2 }), " Join Discard"] })] })] }) })] }) }));
};
const FAQContent = styled(Box) `
  @media (max-height: 860px) {
    height: 469px;
  }
`;
