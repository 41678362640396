import { Button, Flex, useOutsideClick } from '@totejs/uikit';
import styled from '@emotion/styled';
import { ExternalLinkIcon, WithdrawIcon } from '@totejs/icons';
import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useRouter } from 'next/router';
import {
  mobileMedia,
  tabletMedia,
  useResponsive,
  GA_MAP,
  reportEvent,
} from '@op-bridge/bridge-core';
import { WalletKitButton } from '@totejs/walletkit';

import { HeaderTab } from '../header/HeaderTab';
import { HeaderLogo } from '../svgIcon/HeaderLogo';
import ProfileImage from '../svgIcon/ProfileImage';
import { trimLongStr } from '../../utils';
import { HeaderProfileBg } from '../svgIcon/HeaderProfileBg';
import { Copy } from '../Copy';
import { SwitchEnv } from './SwitchEnv';
import { NET_ENV } from '../../env';
import { HeaderLogoMobile } from '../svgIcon/HeaderLogoMobile';
import { HeaderLogoTestnetMobile } from '../svgIcon/HeaderLogoTestnetMobile';
import { HeaderLogoTestnet } from '../svgIcon/HeaderLogoTestnet';

const Header = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { address: wagmiAddress, isConnected, isConnecting } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const [address, setAddress] = useState('');
  const [connected, setIsConneted] = useState(false);
  const ref = useRef(null);
  const router = useRouter() as any;
  const path = router.pathname;
  const [activeKey, setActiveKey] = useState(path);
  const { isMobile, isTablet } = useResponsive();

  const tabs = useMemo(
    () => [
      { key: 'deposit', value: 'Deposit' },
      { key: 'withdraw', value: 'Withdraw' },
      connected ? { key: 'history', value: 'History' } : {},
    ],
    [connected],
  );

  const handleShowDropDown = useCallback(() => {
    setDropDownOpen((preState) => !preState);
  }, []);

  const onChange = async (key: string | number) => {
    const tabName = String(key);
    router.push(`/${tabName}`);
  };

  useEffect(() => {
    const routerPath = path.replace('/', '');
    if (routerPath && tabs?.filter((tab) => tab.key === routerPath)) {
      setActiveKey(routerPath);
    }
  }, [path, tabs]);

  // Check client side wallet connection
  useEffect(() => {
    setIsConneted(isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  useOutsideClick({
    ref,
    handler: () => {
      if (dropDownOpen) {
        setTimeout(() => {
          setDropDownOpen(false);
        }, 50);
      }
    },
  });
  return (
    <FlexContainer
      justifyContent={'space-between'}
      padding={['16px 12px 0px', '10px 24px 0px']}
      height={[56, 64, 64]}
    >
      <ImageContainer className={NET_ENV === 'Testnet' ? 'testnet' : ''}>
        <a
          style={{ height: '38px', display: 'block', position: 'relative' }}
          href={'/'}
          onClick={() => {
            reportEvent({
              name: GA_MAP.headerLogo,
              data: { name: 'Click Logo' },
            });
          }}
        >
          {isMobile ? (
            NET_ENV === 'Testnet' ? (
              <HeaderLogoTestnetMobile width={132} height={32} />
            ) : (
              <HeaderLogoMobile width={132} height={32} />
            )
          ) : NET_ENV === 'Testnet' ? (
            <HeaderLogoTestnet width={181} height={36} />
          ) : (
            <HeaderLogo width={181} height={36}></HeaderLogo>
          )}
        </a>
      </ImageContainer>

      {!isMobile && <HeaderTab tabs={tabs} activeKey={activeKey} onTabChange={onChange} />}

      <Flex gap={12} gridGap={12}>
        <DocButton
          onClick={() => {
            if (window) {
              window.open(`https://docs.combonetwork.io/`);
              reportEvent({ name: GA_MAP.headerDocs, data: { name: 'Click Docs' } });
            }
          }}
        >
          Docs
          <ExternalLinkIcon ml={4} width={12} height={12} />
        </DocButton>
        <SwitchEnv />
        <ButtonWrapper>
          <WalletKitButton.Custom>
            {({ show, address, isConnected }) => {
              return !isConnected && isMobile ? (
                <StyledButton onClick={show}>Connect</StyledButton>
              ) : !isConnected && !isMobile ? (
                <StyledButton onClick={show}>Connect Wallet</StyledButton>
              ) : (
                <ConnectProfile
                  onClick={() => {
                    try {
                      if (isConnected && !dropDownOpen) {
                        handleShowDropDown();
                      }
                    } catch (e) {
                      //eslint-disable-next-line no-console
                      console.log(e);
                    }
                    reportEvent({ name: GA_MAP.headerConnect, data: { name: 'Connect Wallet' } });
                  }}
                >
                  <ProfileWrapper
                    ml={[0, 0, 3]}
                    gap={10}
                    gridGap={10}
                    justifyContent={['flex-start', 'center', 'flex-start']}
                    w={[24, 44, 158]}
                  >
                    <Profile>
                      <ProfileImage width={[20, 40, 32]} height={[20, 40, 32]} />
                    </Profile>
                    {!isMobile && !isTablet && (
                      <div>{address ? trimLongStr(address, 10, 6, 4) : ''}</div>
                    )}
                  </ProfileWrapper>
                </ConnectProfile>
              );
            }}
          </WalletKitButton.Custom>

          {dropDownOpen && isConnected && !isConnecting && (
            <DropDown ref={ref}>
              <HeaderProfileBg width={298} height={96}></HeaderProfileBg>

              <ImageWrapper>
                <ProfileImage width={64} height={64} />
              </ImageWrapper>

              <AddressWrapper>
                <Address mb={24} height={24}>
                  <div>{address ? trimLongStr(address, 10, 6, 4) : ''}</div>
                  <Copy ml={[12, 0, 0]} value={address || ''} />
                </Address>
                <Menu>
                  <MenuElement
                    onClick={async () => {
                      reportEvent({
                        name: GA_MAP?.headerDisconnectClick,
                        data: { name: 'Disconnect Wallet' },
                      });
                      try {
                        disconnectAsync().then(() => {
                          setAddress('');
                        });
                      } catch (e) {
                        //eslint-disable-next-line no-console
                        console.log(e);
                      }
                    }}
                  >
                    <WithdrawIcon
                      mr={8}
                      width={24}
                      height={24}
                      style={{ transform: 'rotate(-90deg)' }}
                    />{' '}
                    Disconnect
                  </MenuElement>
                </Menu>
              </AddressWrapper>
            </DropDown>
          )}
        </ButtonWrapper>
      </Flex>
    </FlexContainer>
  );
};

export default Header;

const ImageContainer = styled.div`
  position: relative;
  ${mobileMedia} {
    margin-top: -5px;
    &.testnet {
      margin-top: -7px;
    }
  }
`;

const FlexContainer = styled(Flex)`
  background-color: ${(props: any) => props.theme.colors.bg?.middle};
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  border-bottom: 1px solid ${(props: any) => props.theme.colors.readable.border};
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 158px;
  height: 44px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 200px;
  padding: 0 24px;
  color: ${(props: any) => props.theme.colors.bg.card};
  background: ${(props: any) => props.theme.colors.scene.primary.normal};
  &.connected {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background: ${(props: any) => props.theme.colors.bg?.middle};
    color: ${(props: any) => props.theme.colors.readable.normal};
    border: 1px solid ${(props: any) => props.theme.colors.readable.border};
    &:hover {
      background: ${(props: any) => props.theme.colors.bg?.card};
    }
  }
  &:hover {
    background: ${(props: any) => props.theme.colors.scene.primary.active};
    color: ${(props: any) => props.theme.colors.bg.card};
  }
  ${mobileMedia} {
    height: 24px;
    max-width: 74px;
  }
`;

const DocButton = styled(Button)`
  height: 44px;
  border-radius: 200px;
  background: ${(props: any) => props.theme.colors.readable.white};
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  color: ${(props: any) => props.theme.colors.readable.normal};
  &:hover {
    background: ${(props: any) => props.theme.colors?.bg.top.active};
    border: 1px solid ${(props: any) => props.theme.colors?.bg.top.active};
    color: ${(props: any) => props.theme.colors.readable.normal};
  }
  @media (max-width: 860px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  button.connected {
    padding: 0 9px 0 4px;
  }
`;

const DropDown = styled.div`
  position: absolute;
  top: calc(100% - 4px);
  color: ${(props: any) => props.theme.colors.bg?.card};
  right: 0;
  border-radius: 12px;
  width: 300px;
  background: ${(props: any) => props.theme.colors.readable.white};
  box-shadow: ${(props: any) => props.theme.shadows.normal};
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
`;

const AddressWrapper = styled.div`
  padding: 12px;
  margin-top: 46px;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

const Address = styled(Flex)`
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  ${mobileMedia} {
    gap: 0;
  }
`;

const Menu = styled(Flex)`
  flex-direction: column;
  gap: 6px;
  grid-gap: 6px;
`;

const MenuElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 8px 12px;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    background: ${(props: any) => props.theme.colors.bg?.top?.normal};
  }
`;

const ImageWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 62px;
  right: 50%;
  transform: translateX(50%);
  background: ${(props: any) => props.theme.colors.readable.white};
  width: 68px;
  height: 68px;
  border-radius: 50%;
`;

const ProfileWrapper = styled(Flex)`
  align-items: center;
  color: ${(props: any) => props.theme.colors.readable.normal};
`;

const Profile = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  ${tabletMedia} {
    width: 44px;
    height: 44px;
  }
  ${mobileMedia} {
    width: 24px;
    height: 24px;
  }
`;

const ConnectProfile = styled(Flex)`
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 158px;
  height: 44px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 200px;
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  &:hover {
    background: ${(props: any) => props.theme.colors.bg.top.active};
  }
  ${mobileMedia} {
    height: 24px;
  }
`;
