import { rgba } from '@totejs/uikit';

export const light = {
  colors: {
    readable: {
      normal: '#1E2026',
      secondary: '#76808F',
      disabled: '#AEB4BC',
      border: '#E6E8EA',
      white: '#FFFFFF',
      link: '#49ACAF',
      pageButton: '#8C8D8F',
      grey10: '#B9B9BB',
      top: {
        secondary: '#474D57',
      },
      footer: { iconHover: '#1E2026' },
    },

    bg: {
      bottom: '#F5F5F5',
      middle: '#FFFFFF',
      card: '#FFFFFF',
      codebox: '#F0FEFE',
      table: '#FFFFFF',
      status: '#F5F5F5',
      top: {
        normal: '#F5F5F5',
        active: '#E6E8EA',
      },
    },

    scene: {
      primary: {
        normal: '#58CED2',
        active: '#76D7DA',
        opacity: rgba('#76D7DA', 0.1),
        semiOpacity: rgba('#76D7DA', 0.15),
        primaryOpacity: rgba('#58CED2', 0.1),
        progressBg: rgba('#58CED2', 0.15),
      },

      success: {
        normal: '#29CA0E',
        active: '#30EE11',
        opacity: rgba('#30EE11', 0.1),
        progressBar: '#02C076',
      },

      danger: {
        normal: '#CA300E',
        active: '#EE3911',
        opacity: rgba('#EE3911', 0.1),
        hover: rgba('#CA300E', 0.2),
      },

      warning: {
        normal: '#CAA20E',
        active: '#EEBE11',
        opacity: rgba('#EEBE11', 0.1),
        hover: rgba('#EEBE11', 0.2),
      },

      orange: {
        normal: '#EE7C11',
        active: '#F1943C',
        opacity: rgba('#F0B90B', 0.1),
        hover: rgba('#EE7C11', 0.2),
        waiting: '#F15903',
      },

      blue: {
        normal: '#4486F0',
        active: '#548FEE',
        approve: { normal: '#1184EE', active: '#3C9AF1' },
      },
    },
  },

  shadows: {
    normal: '0px 4px 24px rgba(0, 0, 0, 0.04)',
  },
};
