import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, useDisclosure, } from '@totejs/uikit';
import styled from '@emotion/styled';
import { HelpIcon } from '../svgIcon/HelpIcon';
import { Discord } from '../svgIcon/Discord';
import { mobileMedia } from '../../hooks';
export const FAQMobile = (props) => {
    const { children, faqTitle, closeGA, discordGA, buttonGA } = props;
    const { isOpen, onToggle, onClose } = useDisclosure();
    return (_jsxs(Box, { width: '180px', bottom: 64, margin: '24px auto 0', children: [_jsxs(Flex, { cursor: 'pointer', color: 'bg.card', fontSize: 16, fontWeight: 700, lineHeight: '20px', padding: '9px 12px', borderRadius: 24, background: 'scene.primary.normal', alignItems: 'center', justifyContent: 'center', _hover: {
                    background: 'scene.primary.active',
                }, onClick: () => {
                    if (buttonGA)
                        buttonGA();
                    onToggle();
                }, children: [_jsx(HelpIcon, { w: 24, h: 24, mr: 2 }), " Help"] }), _jsxs(ModalContainer, { isOpen: isOpen, onClose: onClose, children: [_jsx(ModalCloseButton, { onClick: () => {
                            if (closeGA) {
                                closeGA();
                            }
                        }, _hover: { color: 'scene.primary.normal' } }), _jsx(ModalHeader, { borderBottom: '1px solid', borderBottomColor: 'readable.border', children: faqTitle }), _jsx(ModalBody, { children: children }), _jsxs(ModalFooter, { borderTop: '1px solid', borderColor: 'readable.border', marginTop: 12, flexDirection: 'column', alignItems: 'center', padding: '16px', gap: 12, children: [_jsx(Box, { color: '#fff', w: 208, fontSize: 12, fontWeight: 400, textAlign: 'center', children: "Don't hesitate to contact us if you have more questions." }), _jsxs(Button, { maxW: 200, h: 40, padding: '8px 12px', w: '100%', borderRadius: 24, variant: 'ghost', borderColor: '#fff', color: '#fff', alignItems: 'center', _hover: { background: '#5865F2', color: '#fff', borderColor: '#5865F2' }, onClick: () => {
                                    if (discordGA)
                                        discordGA();
                                    window.open('https://discord.com/invite/bnbchain');
                                }, children: [_jsx(Discord, { h: 24, w: 24, mr: 2 }), " Join Discard"] })] })] })] }));
};
const ModalContainer = styled(Modal) `
  .ui-modal-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: auto;
    background: ${(props) => props.theme.colors.bg.middle};
    padding: 0;

    .ui-modal-header {
      color: #fff !important;
      font-size: 16px;
      line-height: normal;
      font-weight: 700;
      padding: 18px 20px;
      text-align: left;
      justify-content: flex-start;
    }

    .ui-modal-body {
      overflow-y: visible;
    }
  }

  ${mobileMedia} {
    &::after {
      height: 0px;
    }
    &::before {
      height: 0px;
    }
  }
`;
