import type { AppProps } from 'next/app';
import { WagmiConfig, createClient, Chain } from 'wagmi';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, isMobile } from '@totejs/uikit';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { ModalProvider } from '@op-bridge/bridge-core';
import { trustWallet, metaMask } from '@totejs/walletkit/wallets';
import Head from 'next/head';
import '@totejs/walletkit/styles.css';
import { WalletKitOptions, WalletKitProvider, getDefaultConfig } from '@totejs/walletkit';

import { BSCLogo } from '../components/svgIcon/BSCLogo';
import Layout from '../components/layout';
import { theme } from '../theme';
import * as env from '../env';

declare global {
  interface Window {
    trustWallet?: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
    },
  },
  queryCache: new QueryCache({
    onError: () => {},
  }),
});

const bscTestnet: Chain = {
  id: Number(env.L1_CHAIN_ID),
  network: `BNB Smart Chain ${env.NET_ENV}`,
  rpcUrls: {
    default: {
      http: [env.L1_RPC_URL],
    },
    public: {
      http: [env.L1_RPC_URL],
    },
  },
  name: `BNB Smart Chain ${env.NET_ENV}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: { name: 'BscScan', url: env.BSC_SCAN[env.NET_ENV] },
    default: { name: 'BscScan', url: env.BSC_SCAN[env.NET_ENV] },
  },
};

const opChain: Chain = {
  id: Number(env.L2_CHAIN_ID),
  network: `Combo ${env.NET_ENV}`,
  rpcUrls: {
    default: {
      http: [env.L2_RPC_URL],
    },
    public: {
      http: [env.L2_RPC_URL],
    },
  },
  name: `Combo ${env.NET_ENV}`,
  nativeCurrency: {
    name: env.NET_ENV === 'Mainnet' ? 'BNB' : 'tcBNB',
    symbol: env.NET_ENV === 'Mainnet' ? 'BNB' : 'tcBNB',
    decimals: 18,
  },
  blockExplorers: {
    etherscan: { name: 'COMBOTrace', url: env.COMBO_SCAN[env.NET_ENV] },
    default: { name: 'COMBOTrace', url: env.COMBO_SCAN[env.NET_ENV] },
  },
};

const mobile = isMobile();

const connectors = mobile
  ? [
      metaMask({
        connectorOptions: {
          shimDisconnect: true,
          UNSTABLE_shimOnConnectSelectAccount: true,
        },
      }),
    ]
  : [
      trustWallet({
        connectorOptions: {
          shimDisconnect: true,
        },
      }),
      metaMask({
        connectorOptions: {
          shimDisconnect: true,
          UNSTABLE_shimOnConnectSelectAccount: true,
        },
      }),
    ];

const client = createClient(
  getDefaultConfig({
    appName: 'Connect Wallet',
    chains: [bscTestnet, opChain],
    connectors,
    autoConnect: true,
    walletConnectProjectId: '02040edbce5ab6bdf8d622e3630a326c',
  }),
);

function getDefaultLayout(page: ReactNode) {
  return <Layout page={page} />;
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter() as any;
  const chainId =
    router && router?.asPath?.includes('withdraw')
      ? Number(env.L2_CHAIN_ID)
      : Number(env.L1_CHAIN_ID);

  const connectWalletOptions: WalletKitOptions = {
    initialChainId: chainId, // Once connected to the wallet, which chain you want to use
    walletDownloadUrl: mobile ? 'https://metamask.io/download' : 'https://trustwallet.com',
    chainsConfig: [
      {
        id: Number(env.L2_CHAIN_ID),
        name: `Combo ${env.NET_ENV}`,
        logo: <BSCLogo />,
      },
    ],
  };

  const getLayout = (Component as any).getLayout || getDefaultLayout;
  //
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={client}>
        <ThemeProvider theme={theme}>
          <WalletKitProvider options={connectWalletOptions} debugMode>
            <Head>
              <title>
                {`Combo Bridge - Transfer Tokens between Combo${
                  env.NET_ENV === 'Testnet' ? ' Testnet' : ''
                } & BSC${env.NET_ENV === 'Testnet' ? ' Testnet' : ''}`}
              </title>
            </Head>
            <ModalProvider>{getLayout(<Component {...pageProps} />)}</ModalProvider>
          </WalletKitProvider>
        </ThemeProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
}
